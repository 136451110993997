<template>
  <div class="orders">
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('帳單') }}</h2>
      </div>
      <div class="content-tabs">
        <el-tabs v-model="activeName" @tab-click="onTabChange" type="card">
          <el-tab-pane :label="$t('All')" name="All">{{ $t('All') }}</el-tab-pane>
          <el-tab-pane :label="$t('未結帳單')" name="Unpaid">{{ $t('未結帳單') }}</el-tab-pane>
          <el-tab-pane :label="$t('未結外送單')" name="Unfulfilled">{{ $t('未結外送單') }}</el-tab-pane>
          <el-tab-pane :label="$t('掛帳單')" name="Completed">{{ $t('掛帳單') }}</el-tab-pane>
        </el-tabs>
      </div>
      <ItemFilter :placeholder="$t('帳單號碼搜尋')" default="orderNum" @search="searchItems">
        <el-option :label="$t('按帳單號碼搜尋')" value="orderNum"></el-option>
      </ItemFilter>
      <div class="order-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('帳單號碼') }}</th>
              <th scope="col">{{ $t('帳單日期') }}</th>
              <th scope="col">{{ $t('帳單類型') }}</th>
              <th scope="col">{{ $t('帳單總額') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders">
              <td>{{ order.reference_id }}</td>
              <td>{{ formatDateTime(order.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td>未結帳單</td>
              <td>HK${{ loadOrderAmount(order) }}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'Order', params: {id: order.id}}">
                    {{ $t('Edit') }}
                  </router-link>
                </div>
              </td>
            </tr>
            <tr>
              <td>3719410506</td>
              <td>2024-01-05 12:25:15</td>
              <td>未結外送單</td>
              <td>HK$100</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>1301518548</td>
              <td>2024-01-15 11:30:07</td>
              <td>掛帳單</td>
              <td>HK$240</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Order from '@/lib/order';
import ItemFilter from '@/components/Filter.vue';

export default {
  name: 'Orders',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadOrders();
  },
  data(){
    return {
      activeName: 'All',
      orders: [],
      searchOrders: null, //Keep the tab result
      orignalOrders: [],
    }
  },
  methods:{
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'orderNum':
        searchField['reference_id'] = form.filterValue;
        newItems = Common.filterItems(searchField, this.orignalOrders);
        this.searchOrders = newItems;
        this.orders = newItems;
        break;
      }
    },
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    loadOrderAmount(order){
      const relatedMeals = JSON.parse(order.related_meal);
      const mealInfos = order.meal_infos;
      const totalAmount = this.loadItemAmount(mealInfos, relatedMeals);
      return totalAmount;
    },
    loadItemAmount(mealInfos, relatedMeals){
      let totalAmount = 0;
      for(let i = 0; i < relatedMeals.length; i++ ){
        const currentMeal = relatedMeals[i];
        const mealInfo = mealInfos.find(function(item){
          return currentMeal.id === item.id;
        });
        const mealPrice = mealInfo['regular_price'];
        const mealAmount = mealPrice * currentMeal.quantity;
        totalAmount += mealAmount;
      }
      return totalAmount;
    },
    getOptionInfo(mealInfo, variation, option){
      const variationInfo = JSON.parse(mealInfo.meal_variations).find(function(item){
        if(variation.variation_name_en === item.variation_name_en || variation.variation_name_zh === item.variation_name_zh){
          return true;
        }
      });
      const optionInfo = variationInfo.options.find(function(item){
        if(option === item.option_name_en || option === item.option_name_zh){
          return true;
        }
      });
      return optionInfo;
    },
    async loadOrders(){
      try{
        const loginInfo = Common.getLoginInfo();
        const orders = await Order.loadAllOrders(this.apiUrl, loginInfo);
        this.orignalOrders = orders;
        this.orders = orders;
      }catch(err){
        console.log(err);
      }
    },
    async deleteOrder(orderId){
      try{
        const loginInfo = Common.getLoginInfo();
        await Order.deleteOrder(this.apiUrl, orderId, loginInfo);
        const newOrders = this.orders.filter(function(item) {
          return item.id !== orderId;
        });
        this.orignalOrders = newOrders;
        this.orders = newOrders;
      }catch(err){
        console.log(err);
      }
    },
    async onTabChange(){
      const activeName = this.activeName;
      if(activeName !== 'All'){
        this.loadLimitOrders(activeName);
      }else{
        this.orders = this.orignalOrders;
      }
    },
    async loadLimitOrders(type){
      const searchFields = {
        'order_status': '',
      }
      if(type === 'Unpaid'){
        if(this.searchOrders){
          const newItems = this.searchOrders.filter(function(order){
            return order['order_status'] == 'Unpaid';
          });
          this.orders = newItems;
        }else{
          const newItems = this.orignalOrders.filter(function(order){
            return order['order_status'] == 'Unpaid';
          });
          this.orders = newItems;
        }
      }
      if(type === 'Unfulfilled'){
        if(this.searchOrders){
          const newItems = this.searchOrders.filter(function(order){
            return order['order_status'] == 'Unfulfilled';
          });
          this.orders = newItems;
        }else{
          const newItems = this.orignalOrders.filter(function(order){
            return order['order_status'] == 'Unfulfilled';
          });
          this.orders = newItems;
        }
      }
      if(type === 'Completed'){
        if(this.searchOrders){
          const newItems = this.searchOrders.filter(function(order){
            return order['order_status'] == 'Completed';
          });
          this.orders = newItems;
        }else{
          const newItems = this.orignalOrders.filter(function(order){
            return order['order_status'] == 'Completed';
          });
          this.orders = newItems;
        }
      }
      if(type === 'Cancelled'){
        if(this.searchOrders){
          const newItems = this.searchOrders.filter(function(order){
            return order['order_status'] == 'Cancelled';
          });
          this.orders = newItems;
        }else{
          const newItems = this.orignalOrders.filter(function(order){
            return order['order_status'] == 'Cancelled';
          });
          this.orders = newItems;
        }
      }
      if(type === 'All'){
        if(this.searchOrders){
          this.orders = searchOrders;
        }else{
          this.orders = orignalOrders;
        }
      }
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
